<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadPlatorm: {
        total: () => {
          //#e11e25
          //#22315e
          //1e2448

          const root = document.querySelector(":root");

          //VARIAVEIS ALTERAVEIS
          root.style.setProperty("--primary", "#22315e");
          root.style.setProperty("--primary-70", "#22315eB3");
          root.style.setProperty("--primary-70-hover", "#22315eCC");
          root.style.setProperty("--primary-80", "#22315eCC");
          root.style.setProperty("--backdrop-bg", "#e11e2463"); //modal background

          //nav
          root.style.setProperty("--activeMenu", "#e11e25"); //bg LOGO
          root.style.setProperty("--activeMenuHover", "#e11e25"); //hover do texto no menu lateral
          root.style.setProperty("--navBg", "#22315e05"); //bg menu lateral -> nao alterar?

          //layout
          root.style.setProperty("--background", "#22315e05"); //bg geral

          //add favicon
          const favicon = document.getElementById("favicon");
          favicon.href = `/favicon-total.png`;
          //change title
          document.title = "Total Express | management";
        },
        nobordist: () => {
          return;
          /* 
          try {
            require(`./assets/scss/_variables-${this.$helpers.getPlatform()}.scss`)
          } catch (err) { this.loadNbPlatform(); }
          //getComputedStyle(document.documentElement).getPropertyValue('--my-variable-name');
          */

          /* 
          const root = document.querySelector(':root');

          //VARIAVEIS ALTERAVEIS
          root.style.setProperty('--font-family-base', 'Nunito Sans');
          root.style.setProperty('--primary', '#001991');
          root.style.setProperty('--primary-70', '#001991B3');
          root.style.setProperty('--primary-70-hover', '#263A9A');
          root.style.setProperty('--primary-80', '#001991CC');
          root.style.setProperty('--primaryActive', '#000F5A');
          root.style.setProperty('--backdrop-bg', '#00199199'); //modal background

          root.style.setProperty('--secondary', '#456557'); //texto da tabela?
          root.style.setProperty('--btnSecondaryBg', 'transparent');

          //nav
          root.style.setProperty('--navItemHover', '#e1e1f5'); //hover dos menus internos do side bar
          root.style.setProperty('--navText', '#000'); //texto da current nav e titulos da nav
          root.style.setProperty('--navTextUnactive', '#6e6e82');
          root.style.setProperty('--titleSectionSidebar', '#6e6e82'); //titulos das sessoes do side bar
          root.style.setProperty('--activeMenu', '#F0F0FA'); //bg LOGO
          root.style.setProperty('--activeMenuHover', '#000'); //hover do texto no menu lateral
          root.style.setProperty('--navBg', '#ffffff'); //bg menu lateral -> nao alterar?
          root.style.setProperty('--navBgClose', '#ffffff'); //bg dos itens de navegaçao
          root.style.setProperty('--navBgOpen', '#F0F0FA'); //bg dos itens de navegaçao quando abertos
          root.style.setProperty('--backgroundTopBar', '#F7F7FC'); //bg topbar

          //layout
          root.style.setProperty('--background', '#F7F7FC'); //bg geral
          
          //VARIAVEIS PADRAO
          root.style.setProperty('--font-family-pass', 'TextSecurityDisc');

          root.style.setProperty('--success', '#009600');
          root.style.setProperty('--success-10', '#e6f5e6');

          root.style.setProperty('--warning', '#c88200');
          root.style.setProperty('--attention', '#c88200');
          root.style.setProperty('--attention-10', '#fff6e5');

          root.style.setProperty('--danger', '#d1001f');
          root.style.setProperty('--error', '#c80000');
          root.style.setProperty('--error-10', '#fae5e5');

          // colors 
          root.style.setProperty('--mapChartBg', '#eef4f9');

          root.style.setProperty('--lightBlue', '#0275d8');
          root.style.setProperty('--darkBlue', '#0D1442');
          root.style.setProperty('--blue', '#2F3D9C');
          root.style.setProperty('--green', '#248F3D');
          root.style.setProperty('--lightGreen', '#54a021');
          root.style.setProperty('--white', '#fefeff');
          root.style.setProperty('--black', '#000');
          root.style.setProperty('--darkRed', '#8F0101');
          root.style.setProperty('--lightGray', '#f1f1f1');
          root.style.setProperty('--gray', '#d6d6d6');
          root.style.setProperty('--darkGray', '#3E3E3E');
          root.style.setProperty('--transparent', '#ffffff00');
          root.style.setProperty('--btnFilter', '#f8020291');
          root.style.setProperty('--btnFilterBorder', '#f8020254');
          root.style.setProperty('--shadow', '#F0F0FA99');

          root.style.setProperty('--gray-05', '#F0F0FA'); //bg quartenary btn, inputs, input-options, shadow, table head, hover secondary btn, hover card
          root.style.setProperty('--gray-10', '#e1e1f5'); //input hover, hover quartenary btn, lines and hover in input-options,
          root.style.setProperty('--gray-20', '#c8c8dc'); //disabled text bg scrollbar, border card, hover star, hover radio btn
          root.style.setProperty('--gray-30', '#A0A0A0'); //arrows, star borders
          root.style.setProperty('--gray-40', '#9696aa'); //scrollbar, placeholder, some icons
          root.style.setProperty('--gray-50', '#8c8ca0'); //scrollbar hover
          root.style.setProperty('--gray-60', '#6e6e82'); //tertiary btn, checkbox label, radio btn border, some icons, help ballon

          //login
          root.style.setProperty('--loginBG', '#282c4e');

          //size
          root.style.setProperty('--topNavBarHeight', '56px');
          root.style.setProperty('--sideNavBarWidth', '216px');

          //VARIAVEIS IRELEVANTES
          root.style.setProperty('--topNavHover', '#456557');
          root.style.setProperty('--info', '#2b3990');
          root.style.setProperty('--bgLight', '#eef4f9');
          root.style.setProperty('--light', '#f5f9fd');

          //scrollbar
          root.style.setProperty('--scrollbarStartColor', '#006838');
          root.style.setProperty('--scrollbarEndColor', '#282c4e');
          root.style.setProperty('--scrollbarStartColorHover', '#54a021');
          root.style.setProperty('--scrollbarEndColorHover', '#2b3990');

          //nav OLD
          root.style.setProperty('--topNavHover', '#456557'); 
          */
        },
        asia: () => {
          //#080C60
          //#006EFF

          const root = document.querySelector(":root");

          //VARIAVEIS ALTERAVEIS
          root.style.setProperty("--primary", "#080C60");
          root.style.setProperty("--primary-80", "#080C60CC");
          root.style.setProperty("--backdrop-bg", "#080C6099"); //modal background

          //add favicon
          const favicon = document.getElementById("favicon");
          favicon.href = `/favicon-asia.ico`;
          //add title
          document.title = "Asia Shipping | management";
        },
        e_cross: () => {
          try {
            require(`./assets/scss/_variables-${this.$helpers.getPlatform()}.scss`);
          } catch (err) {
            return;
          }

          //#404041
          //#009773
          //#00B88C //verd mais claro

          const root = document.querySelector(":root");

          root.style.setProperty("--font-family-base", "Myriad Pro Regular");

          //VARIAVEIS ALTERAVEIS
          root.style.setProperty("--primary", "#404041");
          root.style.setProperty("--primary-70", "#404041B3");
          root.style.setProperty("--primary-80", "#404041CC");
          root.style.setProperty("--backdrop-bg", "#40404199"); //modal background
          root.style.setProperty("--secondary", "#404041"); //texto da tabela?

          //nav
          root.style.setProperty("--activeMenu", "#404041"); //bg LOGO

          //add favicon
          const favicon = document.getElementById("favicon");
          favicon.href = `/favicon-e-cross.png`;
          //add title
          document.title = "e-Cross | management";
        },
        anjun: () => {
          try {
            require(`./assets/scss/_variables-${this.$helpers.getPlatform()}.scss`);
          } catch (err) {
            return;
          }

          //Montserrat', sans-serif;
          //#009640 // verde main
          //#00773B // verde main pouco mais escuro
          //#053B31 //verde escuro
          //#65AE4C //verde claro
          //#E30613 // vermelho
          //#706F6F // cinza

          const root = document.querySelector(":root");

          root.style.setProperty("--font-family-base", "Montserrat");

          //VARIAVEIS ALTERAVEIS
          root.style.setProperty("--primary", "#00773B");
          root.style.setProperty("--primary-70", "#00773BB3");
          root.style.setProperty("--primary-70-hover", "#009640");
          root.style.setProperty("--primary-80", "#00773BCC");
          root.style.setProperty("--backdrop-bg", "#00773B99"); //modal background

          root.style.setProperty("--secondary", "#706F6F"); //texto da tabela?
          root.style.setProperty("--activeMenu", "#f0faf2"); //bg LOGO
          root.style.setProperty("--activeMenuHover", "#fff"); //hover do texto no menu lateral
          root.style.setProperty("--navBgOpen", "#f0faf2");
          root.style.setProperty("--backgroundTopBar", "#f7fcf7"); //bg topbar

          //nav
          root.style.setProperty("--navItemHover", "#009640"); //hover dos menus internos do side bar

          //layout
          root.style.setProperty("--background", "#f7fcf7"); //bg geral

          //VARIAVEIS IRELEVANTES
          root.style.setProperty("--topNavHover", "#706F6F");

          //add favicon
          const favicon = document.getElementById("favicon");
          favicon.href = `/favicon-anjun.png`;
          //add title
          document.title = "Anjun | management";
        },
        tiendamia: () => {
          //#7800C8 roxo
          //#ff3c3c vermelho

          const root = document.querySelector(":root");

          //VARIAVEIS ALTERAVEIS
          root.style.setProperty("--primary", "#00E6F0");
          root.style.setProperty("--primary-70", "#00E6F0B3");
          root.style.setProperty("--primary-70-hover", "#00E6F0CC");
          root.style.setProperty("--primary-80", "#00E6F0CC");
          root.style.setProperty("--backdrop-bg", "#e2f9fa99"); //modal background
          root.style.setProperty("--secondary", "#6e6e82"); //texto da tabela?
          root.style.setProperty("--backgroundTopBar", "#f6feff"); //bg topbar
          root.style.setProperty("--background", "#f6feff"); //bg geral
          //nav
          root.style.setProperty("--activeMenu", "#e2f9faff"); //bg LOGO
          root.style.setProperty("--activeMenuHover", "#00E6F0"); //hover do texto no menu lateral

          //add favicon
          const favicon = document.getElementById("favicon");
          favicon.href = `/favicon-tiendamia.ico`;
          //add title
          document.title = "tiendamia | management";
        },
        mirakl: () => {
          //add favicon
          const favicon = document.getElementById("favicon");
          favicon.href = `/favicon-mirakl.png`;
          //add title
          document.title = "Samsung | management";
        },
        skypostal: () => {
          //add favicon
          const favicon = document.getElementById("favicon");
          favicon.href = `/favicon-skypostal.ico`;
          //add title
          document.title = "Skypostal | management";
        },
        jt: () => {
          //#e60000
          //#3c3c3c
          //1e2448

          const root = document.querySelector(":root");

          //VARIAVEIS ALTERAVEIS
          root.style.setProperty("--primary", "#3c3c3c");
          root.style.setProperty("--primary-70", "#3c3c3cB3");
          root.style.setProperty("--primary-70-hover", "#3c3c3cCC");
          root.style.setProperty("--primary-80", "#3c3c3cCC");
          root.style.setProperty("--secondary", "#9696aa");
          root.style.setProperty("--backdrop-bg", "#e11e2463"); //modal background

          //nav
          root.style.setProperty("--activeMenuHover", "#e60000"); //hover do texto no menu lateral

          //add favicon
          const favicon = document.getElementById("favicon");
          favicon.href = `/favicon-jt.png`;
          //change title
          document.title = "J&T Express | management";
        },
        correios: () => {
          //#0071ad azul
          //#007bb3 azul claro
          //#00416b azul escuro (hover)
          //#ffd400 amarelo
          //#a65718 amarelo queimado

          const root = document.querySelector(":root");

          //VARIAVEIS ALTERAVEIS
          root.style.setProperty("--primary", "#0071ad");
          root.style.setProperty("--primary-70", "#00416b");
          root.style.setProperty("--primary-70-hover", "#0071ad");
          root.style.setProperty("--primary-80", "#00416b");
          root.style.setProperty("--secondary", "#00416b");
          root.style.setProperty("--backdrop-bg", "#ffd40063"); //modal background

          //nav
          root.style.setProperty("--navText", "#0071ad"); //texto no menu lateral
          root.style.setProperty("--activeMenuHover", "#a65718"); //hover do texto no menu lateral

          //add favicon
          const favicon = document.getElementById("favicon");
          favicon.href = `/favicon-correios.png`;
          //change title
          document.title = "Correios | management";
        },
        shopee: () => {
          const root = document.querySelector(":root");
          root.style.setProperty("--activeMenu", "#ef5436"); //bg LOGO

          //add favicon
          const favicon = document.getElementById("favicon");
          favicon.href = `/favicon-shopee.png`;
          //add title
          document.title = "Shopee | management";
        },
        loggi: () => {
          document.title = "Loggi | management";
        },
        shipsmart: () => {
          document.title = "Shipsmart | management";
        },
        csat: () => {
          document.title = "Shein | management";
        },
      },
    };
  },
  created() {
    this.$store.dispatch("accessibility/load");
    this.loadNbPlatform();
  },
  mounted() {
    const platform = this.$helpers.getPlatform().replace("-", "_");
    this.loadPlatorm[platform]();
  },
  methods: {
    loadNbPlatform() {
      import(`./assets/scss/_variables-nobordist.scss`); //importa font do password
    },
  },
};
</script>

<style lang="scss">
@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import "assets/scss/variables";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap-vue/src/index";
@import "assets/scss/form";
@import "assets/scss/styles";
@import "assets/scss/table";
@import "assets/scss/button";
@import "assets/scss/cards";
@import "assets/scss/collapse";
@import "assets/scss/text";
@import "assets/scss/tooltip";
@import "assets/scss/timeline";
@import "assets/scss/corporate_images";

@import "../node_modules/nprogress/nprogress.css";

.logo {
  max-height: 50px;
}

.logo-sm {
  max-height: 30px;
}
</style>
