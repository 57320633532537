<template>
  <div class="d-flex align-content-between flex-wrap" style="height: 100%">
    <div class="w-100">
      <!-- switch button
      <button class="btn btn-lg nav-button text-primary py-1 pl-2 btn-navswitch" id="popover-target-1"  @click="$emit('switchShowSidenav')">
        <span class="nav-title ml-1">{{ showSidenav ? this.$t('components.baseTemplate.topBar.sideNav') : this.$t('components.baseTemplate.topBar.topNav') }}</span>
      </button>
      <b-popover target="popover-target-1" triggers="hover" placement="top">
        {{ showSidenav ? this.$t('components.baseTemplate.topBar.sideNavPopover') : this.$t('components.baseTemplate.topBar.topNavPopover') }} <b>(  )</b>
      </b-popover>
       -->
      <a class="logo-header" href="/orders">
        <div class="animed-icon logo-minimal sidebar-logo w-100">
          <TotalLogoStamp
            v-if="getPlatform() === 'total'"
            :image="
              require('../../assets/img/PNG/total-express-logo-white.png')
            "
            class="icon-logo-stamp"
            height="34"
            width="146"
          />
          <img
            v-else-if="getPlatform() === 'e-cross'"
            :src="require('../../assets/img/PNG/e-cross-logo-stamp.png')"
            height="60"
            width="178"
            style="margin-top: 10px; margin-left: calc(50% - 92px)"
          />
          <img
            v-else-if="getPlatform() === 'anjun'"
            :src="require('../../assets/img/PNG/anjun-logo.png')"
            height="60"
            width="142"
            style="margin-top: 12px; margin-left: calc(50% - 75px)"
          />
          <img
            v-else-if="getPlatform() === 'tiendamia'"
            :src="require('../../assets/img/PNG/tiendamia-logo.png')"
            height="55"
            width="auto"
            style="margin-top: 10px; margin-left: calc(50% - 75px)"
          />
          <img
            v-else-if="getPlatform() === 'mirakl'"
            :src="require('../../assets/img/PNG/mirakl-logo.png')"
            height="22"
            width="auto"
            style="margin-top: 27px; margin-left: calc(50% - 75px)"
          />
          <img
            v-else-if="getPlatform() === 'skypostal'"
            :src="require('../../assets/img/PNG/skypostal-logo.png')"
            height="38"
            width="auto"
            style="margin-top: 20px; margin-left: calc(50% - 82px)"
          />
          <img
            v-else-if="getPlatform() === 'jt'"
            :src="require('../../assets/img/PNG/jt-logo.png')"
            height="38"
            width="auto"
            style="margin-top: 20px; margin-left: calc(50% - 91px)"
          />
          <img
            v-else-if="getPlatform() === 'correios'"
            :src="require('../../assets/img/PNG/correios-logo.png')"
            height="35"
            width="auto"
            style="margin-top: 20px; margin-left: calc(50% - 91px)"
          />
          <img
            v-else-if="getPlatform() === 'shopee'"
            :src="require('../../assets/img/PNG/shopee-logo.png')"
            height="60"
            width="auto"
            style="margin-top: 10px; margin-left: calc(50% - 38px)"
          />
          <div
            v-else-if="getPlatform() === 'csat'"
            class="d-flex align-items-center justify-content-center h-100"
          >
            <img
              :src="require('../../assets/img/PNG/csat-logo.png')"
              height="20"
              width="auto"
            />
          </div>
          <AsiaLogoStamp
            v-else-if="getPlatform() === 'asia'"
            class="mt-3"
            height="40"
            fill="#13105d"
            width="auto"
          />
          <IconLogo v-else class="icon-logo-stamp" height="34" width="146" />
        </div>
      </a>
      <div class="mt-3">
        <div
          class="route-heading-wrapper"
          v-for="headingsOption in headingsOptions"
          :key="headingsOption.id"
        >
          <div
            v-if="getRoutes[headingsOption].length > 0"
            class="route-heading-title"
          >
            {{ headingsOption }}
          </div>
          <ul
            class="nav flex-column font-weight-light mb-0"
            style="position: relative"
          >
            <li
              class="nav-item nav-side"
              v-for="(route, index) in getRoutes[headingsOption]"
              :key="index"
            >
              <router-link
                :to="route.path"
                class="nav-link text-dark collapsed"
                v-if="route.meta.visible && !route.children"
              >
                <NbIcon
                  v-if="route.meta.icon"
                  class="mr-1"
                  :icon="route.meta.icon"
                  :attributes="{
                    color: 'var(--gray-60)',
                    width: 14,
                    height: 14,
                  }"
                />
                {{ $t(`components.nav.${route.meta.label}`) }}
              </router-link>

              <a
                :href="route.path"
                class="nav-link text-dark collapsed"
                :id="`${route.name}-expand-btn`"
                v-if="route.meta.visible && route.children"
                data-toggle="collapse"
              >
                <NbIcon
                  v-if="route.meta.icon"
                  class="mr-1"
                  :icon="route.meta.icon"
                  :attributes="{
                    color: 'var(--gray-60)',
                    width: 14,
                    height: 14,
                  }"
                />
                {{ $t(`components.nav.${route.meta.label}`) }}
                <NbIcon
                  class="float-right"
                  icon="chevron-down"
                  :attributes="{
                    color: 'var(--gray-40)',
                    'stroke-width': 3,
                    width: '1rem',
                    height: '1rem',
                  }"
                />
              </a>

              <ul
                class="collapse"
                :class="{ show: route.component.name === currentPage }"
                :id="route.name"
                v-if="route.children && route.children.length"
              >
                <div
                  v-for="(routeChildren, indexChildren) in route.children"
                  :key="indexChildren"
                >
                  <router-link :to="routeChildren.path" class="text-dark">
                    <li class="nav-item" v-if="routeChildren.meta.visible">
                      {{ $t(`components.nav.${routeChildren.meta.label}`) }}
                    </li>
                  </router-link>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      <!-- antigo botao support
      <a href="https://nobordist.com/category/support-center/" class="btn btn-primary text-white mt-5" style="position: relative; margin-left: 16px;" target="_blank">
        {{ $t('components.nav.support') }}
      </a>  
      -->
      <div class="experience-btn">
        <div class="experience-icon">
          <NbIcon
            icon="user"
            :attributes="{
              color: 'var(--gray-60)',
              width: '1.5rem',
              height: '1.5rem',
            }"
          />
        </div>
        <div class="link-3">{{ $t("components.nav.myExperience") }} ></div>
      </div>
      <div
        v-if="getPlatform() !== 'e-cross' && getPlatform() !== 'tiendamia'"
        class="copyright-card"
      >
        Powered by Nobordist
      </div>
    </div>
  </div>
</template>

<script>
import IconLogo from "@/components/icons/IconLogo.vue";
import TotalLogoStamp from "@/components/icons/TotalLogoStamp.vue";
import AsiaLogoStamp from "@/components/icons/AsiaLogoStamp.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  name: "MainMenu",
  components: { IconLogo, TotalLogoStamp, AsiaLogoStamp, NbIcon },
  props: {
    currentPage: {
      type: String,
    },
    showSidenav: {
      type: Boolean,
    },
  },
  data: () => ({
    routes: {},
    headingsOptions: ["general", "solutions", "connect", "preferences"],
    expandClasses: {},
  }),
  methods: {
    getPlatform() {
      return this.$helpers.getPlatform();
    },
  },
  created() {
    this.routes = this.$router.options.routes;
  },
  mounted() {
    const activedExpandBtn = document.getElementsByClassName(
      "router-link-exact-active"
    )[0].parentElement.parentElement.previousElementSibling;
    activedExpandBtn.classList.remove("collapsed");
  },
  computed: {
    getRoutes() {
      const headedRoutes = {};

      this.headingsOptions.forEach((headingsOption) => {
        headedRoutes[headingsOption] = this.routes.filter(
          (route) => route.meta.heading === headingsOption
        );
      });
      return headedRoutes;
    },
  },
};
</script>

<style>
.nav-link {
  padding: 0.4rem 0.625rem;
}
.route-heading-wrapper {
  padding: 0.4rem 1rem;
}
.route-heading-wrapper .route-heading-title {
  color: var(--titleSectionSidebar);
  font: normal normal bold 0.625rem/0.875 var(--font-family-base);
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.nav-button {
  right: -96px;
  position: absolute;
}

.icon-logo-stamp {
  z-index: 5;
  margin-top: 24px; /* (logo-header - logo)/2 =  (82 - 34)/2 = 24  */
  margin-left: calc(50% - 73px);
}

.logo-header {
  width: 225px;
  height: 82px;
  margin-bottom: 1.1rem;
  background: var(--gray-05);
  border-radius: 0px 0px 24px 0px;
  opacity: 1;
}

.sidebar-logo {
  width: 13.5rem;
  height: 82px;
  /* padding-top: calc(50% - 17px);
  padding-left: calc(50% - 73px); */
  /* UI Properties */
  background: var(--activeMenu);
  border-radius: 0px 0px 24px 0px;
}

a.active {
  font-weight: normal;
}

/* Side-active */

.nav .nav-item ul > div > .router-link-exact-active {
  color: var(--navText) !important;
  font: normal normal bold 0.875rem/1.25 Nunito Sans !important;
  letter-spacing: 0px;
}
ul > div > .router-link-exact-active > .nav-item {
  /* border-left: 0.3rem solid; */
  color: var(--navText) !important;
  font: normal normal bold 0.875rem/1.25 Nunito Sans !important;
}

.nav-item .collapsing {
  /* transition: all 0.3s ease; */
  list-style-type: none;
  padding: 0px;
  background: var(--gray-05);
  border-radius: 0px 0px 4px 4px;
}

.nav-link.text-dark.collapsed[aria-expanded="false"] ~ .collapsing {
  transition: all 0.3s ease;
  height: 0px;
  background: var(--white);
}

.nav-item .collapsing.show,
.nav-item .collapse.show {
  transition: all 0.3s ease;
  padding-left: 0rem;
  list-style-type: none;
  background: var(--gray-05);
  border-radius: 0px 0px 4px 4px;
}

.nav-side .collapsing .text-dark .nav-item,
.nav-side .collapse .text-dark .nav-item {
  color: var(--navTextUnactive);
  background: var(--navBgOpen);
  transition: all 0.3s ease;
  padding: 0.4rem 2rem;
  font-size: 0.875rem;
  line-height: 1.25;
  font-family: "Nunito Sans";
  letter-spacing: 0px;
}

.nav-side .collapsing .text-dark .nav-item:hover,
.nav-side .collapse .text-dark .nav-item:hover {
  background: var(--navItemHover);
  color: var(--activeMenuHover) !important;
}

.nav-item ul > div > li:before {
  content: "-";
}
.experience-btn {
  transition: all 0.3s ease;
  cursor: pointer;
  margin: 0rem 1rem 0.75rem 1rem;
  background: var(--gray-05);
  border-radius: 4px;
  padding: 1rem;
}
.experience-btn:hover {
  background: var(--navItemHover);
}
.experience-btn:hover .experience-icon {
  background: var(--gray-20);
}
.experience-icon {
  transition: all 0.3s ease;
  float: left;
  width: 2.5rem;
  max-width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
  background: var(--gray-10);
  border-radius: 50%;
}
.experience-icon > span {
  font-size: 1.5em;
  margin-top: calc(50% - 0.75rem);
  margin-left: calc(50% - 0.75rem);
}

.copyright-card {
  padding: 0.5rem 1.5rem;
  width: 13.5rem;
  height: 2rem;
  background: var(--gray-05);
  color: var(--gray-40);
  text-align: left;
  letter-spacing: 0px;
}
</style>
